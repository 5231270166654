<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Customers from '@/services/Customers'
import modalEditUser from "@/components/modals/customers/modalEditUser";
import modalEditPhone from "@/components/modals/customers/modalEditPhone";
import modalEditAddress from "@/components/modals/customers/modalEditAddress";
import modalEditEmail from "@/components/modals/customers/modalEditEmail";
import modalEditAttributes from "@/components/modals/customers/modalEditAttributes";
import modalAddAddress from "@/components/modals/customers/modalAddAddress";
import modalAddPhone from "@/components/modals/customers/modalAddPhone";
import modalSendPushNotification from "@/components/modals/customers/modalSendPushNotification";
import modalChangePassword from "@/components/modals/customers/modalChangePassword";
import TabCustomerDevices from "@/components/tabs/tabCustomerDevices";
import TabCustomerOrders from "@/components/tabs/tabCustomerOrders";
import TabCustomerTicketing from "@/components/tabs/tabCustomerTicketing";
import TabCustomerCashless from "@/components/tabs/tabCustomerCashless";
import TabFanExperience from "@/components/tabs/tabFanExperience";

import Swal from "sweetalert2";

/**
 * Profile component
 */
export default {
    components: {Layout,PageHeader,modalEditPhone, modalEditAddress, modalEditEmail, modalEditAttributes, modalAddAddress,
        modalAddPhone, TabCustomerDevices, TabCustomerOrders, modalEditUser, modalSendPushNotification, modalChangePassword,
        TabCustomerTicketing, TabCustomerCashless , TabFanExperience },
    page: {
        title: "Profile",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            blurConfig: {
                isBlurred: false, // activate and deactivate based on a boolean value and also provide a config
                opacity: 0.1,
                filter: 'blur(1.5px)',
                transition: 'all .3s linear'
            },
            title: "Profile",
            items: [
                {
                    text: "Users",
                },
                {
                    text: "Users",
                    active: true,
                },
            ],
            customerInfo: {
                id: '',
                email: '',
                first_name: '',
                last_name: '',
                middle_name: '',
                initials: '',
                date_of_birth: '',
                gender: '',
                created_on: '',
                nickname: '',
                salutation: '',
                status: '',
            },
            customerEmails: {},
            customerAddresses: {},
            customerPhones: {},
            customerAttributes: {},
            customerAttributesOptIn: {},
            error: null,
            modalData: {},
            customer_id: '',
            toggleProfile: false,
            showLoader:false,
            customerTicketingProvider: '',
            customerProviderData: []
        };
    },
    async mounted() {
        this.getCustomer();
        this.getOptIns();
    },
    methods: {

        getCustomer(){
            this.showLoader = true;
            Customers.getCustomer(this.$route.params.id)
                .then(response => {
                    this.customerInfo =  response.data.customer.info;
                    this.customerEmails = response.data.customer.emails ? response.data.customer.emails : [];
                    this.customerAddresses = response.data.customer.address ? response.data.customer.address : [];
                    this.customerPhones = response.data.customer.phones ? response.data.customer.phones : [];
                    this.customerAttributes = response.data.customer.attributes ? response.data.customer.attributes : [];
                })
                .catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                })
                .finally(() => {
                    this.showLoader = false;
                })
        },

        async getOptIns(){
            await Customers.getCustomerOptIns(this.$route.params.id)
            .then(response => {
                this.customerAttributesOptIn = response.data.data;

            })
            .catch(error => {
                  this.error = error.response.data.error ? error.response.data.error : "";
            })
        },

        formatAttribute(attributeName){
            attributeName = attributeName.replace(/_/g, ' ');
            return attributeName.charAt(0).toUpperCase() + attributeName.slice(1);
        },
        showGender(){
            var genderIcon = "";
            if(this.customerInfo.gender == 'M'){
                genderIcon = '<i class="fas fa-mars text-info" title="Male"></i>';
            }else if(this.customerInfo.gender == 'F'){
                genderIcon = '<i class="fas fa-venus text-danger" title="Female"></i>';
            }else{
                genderIcon = '<i class="fas fa-genderless" title="No Gender"></i>';
            }

            return genderIcon;
        },


        callModalAddressAdd(customerID){
            this.$bvModal.show("address_add");
            this.customer_id = customerID;
        },
        callModalAddressEdit(data) {
            this.$bvModal.show("address_edit");
            this.modalData = data;
        },
        callModalPhoneAdd(customerID) {
            this.$bvModal.show("phone_add");
            this.customer_id = customerID;
        },
        callModalPhoneEdit(data) {
            this.$bvModal.show("phone_edit");
            this.modalData = data;
        },
        callModalMailEdit(data) {
            this.$bvModal.show("mail_edit");
            this.modalData = data;
        },
        callModalUserEdit(data) {
            this.$bvModal.show("user_edit");
            this.modalData = data;
        },
        callModalSendPushNotification(data) {
            this.$bvModal.show("send_notification");
            this.modalData = data;
        },
        callModalChangePassword(data) {
            this.$bvModal.show("change_password");
            this.modalData = data;
        },

        addressRemove(id){
            Swal.fire({
                title: "Remove this user address?",
                text: "This Address will be removed from the user!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Remove!",
            }).then((result) => {
                if (result.value) {
                    try {
                        Customers.removeAddress(id)
                            .then((response) => {
                                const res = response.data ? response.data : false;
                                const error = response.data.error ? response.data.error : 'Failed';
                                if(res){
                                    this.getCustomer()
                                    Swal.fire("Remove from user address", "Address is successfully removed from the list!", "success");
                                }else{
                                    Swal.fire("Fail!", error, "warning");
                                }
                            })

                    }catch(error){
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }
                }
            });
        },

        phoneRemove(id){
            Swal.fire({
                title: "Remove this user phone?",
                text: "This phone will be removed from the user!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Remove!",
            }).then((result) => {
                if (result.value) {
                    try {
                        Customers.removePhone(id)
                            .then((response) => {
                                const res = response.data ? response.data : false;
                                const error = response.data.error ? response.data.error : 'Failed';
                                if(res){
                                    this.getCustomer()
                                    Swal.fire("Remove from user phone", "Phone is successfully removed from the list!", "success");
                                }else{
                                    Swal.fire("Fail!", error, "warning");
                                }
                            })

                    }catch(error){
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }
                }
            });
        },
        
deleteCustomer() {
    Swal.fire({
        title: 'Action Delete Customer',
        icon: 'warning',
        html: `
            <p>Please note that all data related to this customer will be deleted!</p>
            <h5>Are you sure you want to delete this account?</h5>
            <p>Type "delete" to confirm:</p>
            <input type="text" id="deleteConfirmation" class="swal2-input" placeholder="delete">
        `,
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            const confirmationText = Swal.getPopup().querySelector('#deleteConfirmation').value;
            if (confirmationText !== 'delete') {
                Swal.showValidationMessage('You must type "delete" to confirm');
                return false;
            }
            return Customers.deleteCustomer(this.$route.params.id)
                .then((response) => {
                    return response;
                })
                .catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    Swal.fire("Fail!", this.error, "error");
                });
        }
    }).then((result) => {
        if (result.isConfirmed) {
            const res = result.value.data ? result.value.data : false;
            const error = result.value.data.error ? result.value.data.error : 'Failed';
            if (res) {
                Swal.fire("Delete Account", "Customer is successfully deleted!", "success")
                    .then(() => {
                        this.$router.push({ name: 'customers' });
                    });
            } else {
                Swal.fire("Fail!", error, "warning");
            }
        }
    });
},


        toggle() {
            this.toggleProfile = !this.toggleProfile
        },

        customerStatus(){
            return this.customerInfo.status;
        },

        setProviderData(provider, providerData){
            this.customerTicketingProvider = provider
            this.customerProviderData = providerData
        },

        callModalCompanyNameEdit(customerID){
            this.$bvModal.show("edit_attributes");
            this.customer_id = customerID;
        }
    },
    computed:{
        fullName(){
            return [this.customerInfo.first_name, this.customerInfo.middle_name, this.customerInfo.last_name].filter(Boolean).join(" ");
        },
        avatar(){
            return this.customerAttributes.avatar ? this.customerAttributes.avatar : "";
        }
    }
    
};
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mb-4">
        <div v-if="!toggleProfile" class="col-xl-4">
            <div class="card h-100">
                <div class="card-body" v-if="!showLoader">
                    <div class="text-center">

                        <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-v"></i>
                            </template>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalUserEdit(customerInfo)">Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalSendPushNotification(customerInfo)">Send Notification</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalChangePassword(customerInfo)">Change Password</a>
                            <a class="dropdown-item d-none" href="#">Remove</a>
                        </b-dropdown>
                        <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                        <div class="clearfix"></div>
                        <div class="d-none">
                            <img v-if="avatar" src="@/assets/images/users/avatar-generic.png" alt class="avatar-lg rounded-circle img-thumbnail" />
                            <img v-else src="@/assets/images/users/avatar-generic.png" alt class="avatar-lg rounded-circle img-thumbnail" />
                        </div>
                        <h3 class="mt-3 mb-1">{{ fullName }} <span v-html="showGender()"></span></h3>
                        <small class="mt-3 mb-1">{{ customerInfo.hasOwnProperty('id') ?  customerInfo.id : null }}</small>
                        <p class="mt-2 mb-1">{{ customerInfo.nickname }}</p>
                        <div class="mt-2">
                            <div class="font-size-18" v-html="format_status(customerStatus())"></div>
                        </div>
                    </div>

                    <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <h5>Contact Information</h5>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Emails :</p>
                                <p v-for="cusEmail in customerEmails" :key="cusEmail.email_id" class="font-size-16 mb-2 hidden_actions justify-content-between">
                                    <span class="mw-80">{{cusEmail.email}}
                                        <i v-if="cusEmail.primary=='Y'" class="fas fa-circle text-success font-size-14 d-none" title="Primary email"></i>
                                    </span>

                                    <a href="javascript:void(0);" title="Edit email" class="px-2 text-primary float-end" @click="callModalMailEdit(cusEmail)">
                                        <i class="uil uil-pen font-size-18"></i>
                                    </a>
                                </p>

                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Addresses :
                                    <a href="javascript:void(0);" title="Add address" class="px-2 text-primary float-end" @click="callModalAddressAdd(customerInfo.id)">
                                        <i class="uil uil-plus font-size-18"></i>
                                    </a>
                                </p>

                                <p v-for="cusAddress in customerAddresses" :key="cusAddress.address_id" class="font-size-16 mb-2 hidden_actions d-flex justify-content-between">
                                    <span class="mw-80">
                                        <i v-if="cusAddress.address_type=='business'" class="mdi mdi-office-building-outline me-2 d-none" title="Work address"></i>
                                        <i v-if="cusAddress.address_type=='home'" class="mdi mdi-home-variant-outline me-2 d-none" title="Home address"></i>
                                        {{cusAddress.line1 + ' ' + cusAddress.line2 +  (cusAddress.line3 ? ' ' + cusAddress.line3 : '') + ', ' + cusAddress.zip + ' ' +  cusAddress.city + ', ' +  cusAddress.country  }}
                                        <i v-if="cusAddress.primary=='Y'" class="fas fa-circle text-success font-size-14 me-2 d-none" title="Primary address"></i>
                                        <i v-if="cusAddress.verified=='Y'" class="fas fa-check text-success font-size-14 me-2 d-none" title="Verified address"></i>
                                        <i v-if="cusAddress.mailing=='Y'" class="fas fa-envelope text-success font-size-14 me-2 d-none" title="Mailing address"></i>
                                    </span>
                                    <span>
                                    <a href="javascript:void(0);" title="Edit address" class="px-2 text-primary float-end" @click="callModalAddressEdit(cusAddress)">
                                            <i class="uil uil-pen font-size-18"></i>
                                        </a>
                                        <a href="javascript:void(0);" title="Remove address" class="float-end text-danger" @click="addressRemove(cusAddress.address_id)">
                                            <i class="uil uil-trash-alt font-size-18"></i>
                                        </a>
                                    </span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Phones :
                                    <a href="javascript:void(0);" title="Add phone" class="px-2 text-primary float-end" @click="callModalPhoneAdd(customerInfo.id)">
                                        <i class="uil uil-plus font-size-18"></i>
                                    </a>
                                </p>
                                <p v-for="cusPhone in customerPhones" :key="cusPhone.phone_id" class="font-size-16 mb-2 hidden_actions d-flex justify-content-between">
                                    <span class="mw-80">
                                        <i v-if="cusPhone.phone_type=='mobile'" class="mdi mdi-cellphone me-2" title="Mobile"></i>
                                        <i v-if="cusPhone.phone_type=='fixed'" class="mdi mdi-deskphone me-2" title="Fixed"></i>
                                        <span v-if="cusPhone.phone_number">{{ cusPhone.phone_number }} </span>
                                        <span v-else>{{ cusPhone.phone_raw }} </span>

                                        <i v-if="cusPhone.primary=='Y'" class="fas fa-circle text-success font-size-14 d-none" title="Primary phone"></i>
                                    </span>

                                    <span>
                                        <a href="javascript:void(0);" title="Edit phone" class="px-2 text-primary float-end" @click="callModalPhoneEdit(cusPhone)">
                                            <i class="uil uil-pen font-size-18"></i>
                                        </a>
                                        <a href="javascript:void(0);" title="Remove phone" class="float-end text-danger" @click="phoneRemove(cusPhone.phone_id)">
                                            <i class="uil uil-trash-alt font-size-18"></i>
                                        </a>
                                    </span>
                                </p>
                            </div>
                            <div class="mt-3" v-if="customerProviderData && customerProviderData.CustomerType == 1 && customerTicketingProvider == 'TYMES4'">
                                <p class="mb-2 text-strong">Company Name :
                                    <a href="javascript:void(0);"
                                       title="Add Company Name"
                                       class="px-2 text-primary float-end"
                                       @click="callModalCompanyNameEdit(customerInfo.id)"
                                       v-if="!customerAttributes.company_name"
                                    >
                                        <i class="uil uil-plus font-size-18"></i>
                                    </a>
                                </p>
                                <p class="font-size-16 mb-2 hidden_actions justify-content-between" v-if="this.customerAttributes && customerAttributes.company_name">
                                    <span class="mw-80">{{customerAttributes.company_name}}</span>
                                    <a href="javascript:void(0);" title="Edit Company Name" class="px-2 text-primary float-end" @click="callModalCompanyNameEdit(customerInfo.id, customerAttributes)">
                                        <i class="uil uil-pen font-size-18"></i>
                                    </a>
                                </p>
                            </div>

                            <hr>

                            <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Send Push Notification" @click="callModalSendPushNotification(customerInfo)"><i class="uil-message"></i> Send Notification</b-button>
                            <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Change Password" @click="callModalChangePassword(customerInfo)"><i class="uil-lock"></i> Change Password</b-button>
                            <b-button class="mb-2 d-block m-auto mwb-11" variant="danger" title="Delete Account" @click="deleteCustomer()"><i class="uil uil-trash-alt"></i>Delete Account</b-button>

                            <div class="mt-5">
                                <p class="mb-1">Created : {{ customerInfo.hasOwnProperty('created_on') ? customerInfo.created_on : null  }}</p>
                            </div>
                        </div>

                </div>
                <div class="card-body d-flex align-items-center justify-content-center" v-else>
                    <b-spinner large></b-spinner>
                </div>
            </div>
        </div>
        <div v-else class="col-xl-2">
            <div class="card h-100">
                <div class="card-body">
                    <div class="text-center">
                        <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-v"></i>
                            </template>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalUserEdit(customerInfo)">Edit</a>
                            <a class="dropdown-item d-done" href="javascript:void(0);" @click="callModalSendPushNotification(customerInfo)">Send Notification</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalChangePassword(customerInfo)">Change Password</a>
                        </b-dropdown>
                        <i class="uil uil-angle-right float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                        <div class="clearfix"></div>
                        <div class="d-none">
                            <img v-if="avatar" src="@/assets/images/users/avatar-generic.png" alt class="avatar-lg rounded-circle img-thumbnail" />
                            <img v-else src="@/assets/images/users/avatar-generic.png" alt class="avatar-lg rounded-circle img-thumbnail" />
                        </div>
                        <h5 class="mt-3 mb-1">{{ fullName }} <span v-html="showGender()"></span></h5>
                        <small class="mt-3 mb-1">{{ customerInfo.hasOwnProperty('id') ? customerInfo.id : null }}</small>

                        <div class="mt-4">
                            <div class="font-size-18" v-html="format_status(customerStatus())"></div>
                        </div>
                    </div>
                    <hr class="my-2" />

                    <div class="table-responsive mt-4 mb-0">
                        <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Send Push Notification" @click="callModalSendPushNotification(customerInfo)"><i class="uil-message"></i> Send Notification</b-button>
                        <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Change Password" @click="callModalChangePassword(customerInfo)"><i class="uil-lock"></i> Change Password</b-button>
                    </div>
                </div>
            </div>
        </div>

        <div :class="!toggleProfile ? 'col-xl-8':'col-xl-10'">
            <div class="card mb-0">
                <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                    <b-tab active>
                        <template v-slot:title>
                            <i class="uil uil-user-circle font-size-20"></i>
                            <span class="d-none d-sm-block">About</span>
                        </template>
                        <template v-if="!showLoader">
                            <div class="row">
                                <div class="col-sm-12 col-md-3">
                                    <div class="mb-2">
                                        <p class="mb-1 text-strong">Username :</p>
                                        <p class="font-size-16">{{ customerInfo.hasOwnProperty('email') ?  customerInfo.email : null }}</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                  <div class="mb-2">
                                    <p class="mb-1 text-strong">Nickname :</p>
                                    <p class="font-size-16">{{ customerInfo.nickname ?  customerInfo.nickname : null }}</p>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="mb-2">
                                        <p class="mb-1 text-strong">Birthdate :</p>
                                        <p class="font-size-16">{{ customerInfo.hasOwnProperty('date_of_birth') ?  customerInfo.date_of_birth : null  }}</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="mb-2">
                                        <p class="mb-1 text-strong">Gender:</p>
                                        <p class="font-size-16">{{this.customerInfo.gender}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <p class="mb-1 text-strong">OptIns:</p>
                                    <p v-for="(attr) in customerAttributesOptIn" v-bind:key="attr.attribute" class="font-size-16 mb-2">
                                        <i v-if="attr.value=='1'"  class="mdi mdi-check-circle text-success me-2"></i>
                                        <i v-else  class="mdi mdi-alpha-x-circle text-danger me-2"></i>
                                        {{attr.description}}
                                    </p>
                                </div>
                            </div>

                        </template>
                        <template v-else>
                            <div class="card-body d-flex align-items-center justify-content-center" >
                                <b-spinner large></b-spinner>
                            </div>
                        </template>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil uil-mobile-vibrate font-size-20"></i>
                            <span class="d-none d-sm-block">Devices</span>
                        </template>
                        <div class="row">
                            <TabCustomerDevices :customer_id="this.$route.params.id" />
                        </div>

                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil uil-list-ui-alt font-size-20"></i>
                            <span class="d-none d-sm-block">Orders</span>
                        </template>
                        <div class="row">
                            <TabCustomerOrders :customer_id="this.$route.params.id" />
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil uil-ticket font-size-20"></i>
                            <span class="d-none d-sm-block">Ticketing</span>
                        </template>
                        <div class="row">
                            <TabCustomerTicketing :customer_id="this.$route.params.id" @setProviderData="setProviderData" />
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil-credit-card font-size-20"></i>
                            <span class="d-none d-sm-block">Cashless</span>
                        </template>
                        <div>
                           <TabCustomerCashless :customer_id="this.$route.params.id" />
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil uil-trophy font-size-20"></i>
                            <span class="d-none d-sm-block">Fan Experience</span>
                        </template>
                        <div class="row">
                            <TabFanExperience :customer_id="this.$route.params.id"/>
                            <!--Fan Experience goes here-->
                        </div>
                    </b-tab>
                    <!--<b-tab>
                        <template v-slot:title>
                            <i class="uil uil-envelope-alt font-size-20"></i>
                            <span class="d-none d-sm-block">Activity</span>
                        </template>
                        <div>
                            Activity goes here
                        </div>
                    </b-tab>-->
                </b-tabs>
            </div>
        </div>
    </div> 
    <!-- end row -->


    <!-- MODALS -->
    <modalEditPhone :phoneData="modalData" @onRefresh="getCustomer()"></modalEditPhone>
    <modalAddPhone :customer_id="customer_id" @onRefresh="getCustomer()"></modalAddPhone>
    <modalEditAddress :addressData="modalData" @onRefresh="getCustomer()"></modalEditAddress>
    <modalAddAddress :customer_id="customer_id" @onRefresh="getCustomer()"></modalAddAddress>
   <modalEditEmail :emailData="modalData" @onRefresh="getCustomer()"></modalEditEmail>
   <modalEditUser :userData="modalData" @onRefresh="getCustomer()"></modalEditUser>
   <modalEditAttributes :customer_id="customer_id" :userData="customerAttributes" @onRefresh="getCustomer()"></modalEditAttributes>
   <modalSendPushNotification :userData="modalData" @onRefresh="getCustomer()"></modalSendPushNotification>
   <modalChangePassword :userData="modalData" @onRefresh="getCustomer()"></modalChangePassword>
    <!-- END  MODALS -->
</Layout>
</template>
